import React from "react";
import ReactTooltip from "react-tooltip";

class GlobalInformation extends React.Component {
  render() {
    return (
      <div className="col-md-3 ">
        <div className="kt-widget26">
          <div className="kt-widget26__content">
            <span className="kt-widget26__desc">{this.props.title}</span>
            <span className="kt-widget26__number">
              {this.props.total}

              {this.props.subtotal && (
                <small className="fs-16">
                  {" "}
                  {this.props.subtotal}
                  {this.props.signal}
                </small>
              )}

              {this.props.std != null && (
                <small className="fs-16">
                  <small data-for="t-std" data-tip="Desvio Padrão">
                    {" "}
                  </small>{" "}
                  {this.props.std} std
                </small>
              )}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default GlobalInformation;
