import React, { Component } from "react";
import moment from "moment";
const helpers = {
  formatNumber: function (number) {
    return Math.round(number * 100) / 100;
  },

  formatDateTime: function (date) {
    var date2 = moment(date);
    var dateComponent = date2.format("YYYY-MM-DD HH:mm:ss");
    return dateComponent;
  },

  convertMiliseconsToSeconds(time) {
    return time / 60000;
  },

  getTime(date) {
    return moment(date).format("HH:mm");
  },

  getDate(date) {
    return moment(date).format("YYYY-MM-DD");
  },

  formatTime(time_param) {
    var seconds = (time_param / 1000).toFixed(1);
    var minutes = (time_param / (1000 * 60)).toFixed(1);
    var hours = (time_param / (1000 * 60 * 60)).toFixed(1);
    var days = (time_param / (1000 * 60 * 60 * 24)).toFixed(1);

    let time,
      description = "";
    if (seconds < 60) {
      time = seconds;
      description = time > 1 ? "segundos" : "segundo";
    } else if (minutes < 60) {
      time = minutes;
      description = time > 1 ? "minutos" : "minuto";
    } else if (hours < 24) {
      time = hours;
      description = time > 1 ? "horas" : "hora";
    } else {
      time = Math.round(days, 0);
      description = time > 1 ? "dias" : "dia";
    }

    return (
      <span>
        {this.formatNumber(time)} <small className="fs-14">{description}</small>
      </span>
    );
  },

  getPriorityColor(id) {
    switch (id) {
      case 3:
        return "#faa406";
        break;
      case 4:
        return "#017901";
        break;
      case 5:
        return "#f00606";
        break;
    }
  },

  splitName(description) {
    var sep = description.split("(");
    return sep[0];
  },

  getMonthName(n) {
    switch (n) {
      case 1:
        return "Janeiro";
        break;
      case 2:
        return "Fevereiro";
        break;
      case 3:
        return "Março";
        break;
      case 4:
        return "Abril";
        break;
      case 5:
        return "Maio";
        break;
      case 6:
        return "Junho";
        break;
      case 7:
        return "Julho";
        break;
      case 8:
        return "Agosto";
        break;
      case 9:
        return "Setembro";
        break;
      case 10:
        return "Outubro";
        break;
      case 11:
        return "Novembro";
        break;
      case 12:
        return "Dezembro";
        break;
    }
  },

  getDayName(d) {
    switch (d) {
      case 1:
        return "Domingo";
        break;
      case 2:
        return "Segunda-feira";
        break;
      case 3:
        return "Terça-feira";
        break;
      case 4:
        return "Quarta-feira";
        break;
      case 5:
        return "Quinta-feira";
        break;
      case 6:
        return "Sexta-feira";
        break;
      case 7:
        return "Sábado";
        break;
    }
  },

  randomColorGenerator() {
    return "#" + (Math.random().toString(16) + "0000000").slice(2, 8);
  },
};

export default helpers;
