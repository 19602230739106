import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import helpers from "../utils/functions";

export default class TicketModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  render() {
    let ticketInfo = this.props.ticketInformation;

    if (!this.props.show) {
      return null;
    } else {
      return (
        <Modal
          centered={true}
          show={this.props.show}
          onHide={this.props.handleClose}
        >
          <Modal.Header>
            <Modal.Title>
              Detalhes do Pedido <strong>#{ticketInfo.ticket_id}</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <strong>Projeto</strong>
                    <p>{ticketInfo.project}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <strong>Cliente</strong>
                    <p>{ticketInfo.client}</p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <strong>Colaborador</strong>
                    <p>{ticketInfo.helpdesk}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <strong>Autor</strong>
                    <p>{ticketInfo.author ? ticketInfo.author.name : ""}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <strong>Prioridade</strong>
                    <p>{ticketInfo.priority}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <strong>Estado</strong>
                    <p>{ticketInfo.status}</p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <strong>Tipo</strong>
                    <p>{ticketInfo.tracker}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <strong>Avaliação</strong>
                    <p>{ticketInfo.evaluation ? ticketInfo.evaluation : "-"}</p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <strong>Data de Início</strong>
                    <p>{helpers.formatDateTime(ticketInfo.created_on)}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <strong>Data de Fim</strong>
                    <p>{helpers.formatDateTime(ticketInfo.closed_on)}</p>
                  </div>
                </div>
              </div>
              <div className="form-group mb-2">
                <strong>Assunto</strong>
                <p>{ticketInfo.subject}</p>
              </div>

              <div className="form-group mb-2">
                <strong>Descrição</strong>
                <p>{ticketInfo.description}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <a
              href="#"
              className="btn btn-secondary pointer"
              onClick={this.props.onHide}
            >
              Fechar
            </a>
          </Modal.Footer>
        </Modal>
      );
    }
  }
}
