import React from "react";
import { connect } from "react-redux";

const Alerta = props => (
  <div
    id="show"
    className={"alert styleAlert " + props.text.classAlert}
    role="alert"
  >
    {props.text.text}
  </div>
);

const mapStateToProps = state => ({
  text: state.textReducer.text,
  classAlert: state.textReducer.classAlert
});

export default connect(mapStateToProps)(Alerta);
