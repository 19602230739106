import React from 'react';
import { NavLink } from "react-router-dom";

class StickyToolbar extends React.Component {
    render() {


        return (
            <ul className="kt-sticky-toolbar">
                <li className="kt-sticky-toolbar__item kt-sticky-toolbar__item--success" data-toggle="kt-tooltip" title="Globais" data-placement="right">
                    <NavLink to="/globais" className="kt-menu__link kt-menu__toggle">
                        <i className="flaticon-dashboard"></i>
                    </NavLink>
                </li>
                <li className="kt-sticky-toolbar__item kt-sticky-toolbar__item--brand" data-toggle="kt-tooltip" title="Por Colaborador" data-placement="left">
                    <NavLink to="/colaborador" className="kt-menu__link kt-menu__toggle">
                        <i className="flaticon-folder"></i>
                    </NavLink>
                </li>
                <li className="kt-sticky-toolbar__item kt-sticky-toolbar__item--warning" data-toggle="kt-tooltip" title="Por Produto" data-placement="left">
                    <NavLink to="/produto" className="kt-menu__link kt-menu__toggle">
                        <i className="fa fa-user"></i>
                    </NavLink>
                </li>
                <li className="kt-sticky-toolbar__item kt-sticky-toolbar__item--danger" data-toggle="kt-tooltip" title="Temporais" data-placement="left">
                    <NavLink to="/temporais" className="kt-menu__link kt-menu__toggle">
                        <i className="flaticon-calendar-with-a-clock-time-tools"></i>
                    </NavLink>
                </li>

                <li className="kt-sticky-toolbar__item" id="kt_sticky_toolbar_login" data-toggle="kt-tooltip" title="Logout" data-placement="left">
                    <NavLink to="/" className="kt-menu__link kt-menu__toggle">
                        <i className="fa fa-sign-out-alt"></i>
                    </NavLink>
                </li>
            </ul>

        );
    }
}

export default StickyToolbar;