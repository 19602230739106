import React from 'react';

class Select extends React.Component {
    render() {
        return (
            <select value={this.props.value} className="form-control kt-selectpicker select-ig" onChange={this.props.onChange}>
                <option value="">{this.props.title}</option>
                {this.props.information.map((item, p) =>
                    <option key={p} value={item._id.name}>{item._id.name}</option>
                )}
            </select>
        );
    }
}

export default Select;