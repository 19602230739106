import React from 'react';

class TitleComponent extends React.Component{
    render(){
        return(
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
				<div className="kt-subheader__main">
					<h3 className="kt-subheader__title">{this.props.title}</h3>
				</div>
			</div>
        );
    }
}

export default TitleComponent;