import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { showAlerta } from '../redux/actions/flashAlerts'

 const AdicionarAlerta = (AlertaComponent) => {
    class Adicionar extends Component{
        render() {  
            return(
                <AlertaComponent {...this.props}/>
            )
        }
    }   

    const mapDispatchToProps = (dispatch) => bindActionCreators({ showAlerta }, dispatch);

    return connect(null, mapDispatchToProps)(AlertaComponent)
}

export default AdicionarAlerta;