import React from "react";
import ReactTooltip from "react-tooltip";
import helpers from "../utils/functions";

class AverageResponseTimeTable extends React.Component {
  getClass(id) {
    let class_name = "kt-widget17__subtitle";
    switch (id) {
      case 3:
        class_name += " color-green";
        break;
      case 4:
        class_name += " color-orange";
        break;
      case 5:
        class_name += " color-red";
        break;
      default:
        class_name = "";
    }
    return class_name;
  }

  getSLA(description, only_days = false) {
    var sep = description.split("(");
    var days = sep[1].split(" ")[0];

    if (only_days) {
      return parseInt(days);
    } else {
      return days == 1 ? days + " dia" : days + " dias";
    }
  }

  checkIfIsDay(miliseconds) {
    let minutes = parseInt(miliseconds / 6000);
    let hours = parseInt(minutes / 60);
    let days = parseInt(hours / 24);

    if (days > 0) {
      return days;
    }

    return false;
  }

  minutesToDays(minutes) {
    let hours = minutes / 60.0;
    let days = hours / 24.0;

    let formattedTime = Math.round(days * 10) / 10;

    return formattedTime;
  }

  setArrow(averageDurationDays, description) {
    let referenceSLA = this.getSLA(description, true);

    if (averageDurationDays > referenceSLA) {
      return (
        <small>
          <i
            data-for="t-info"
            data-tip="Fora do valor esperado"
            className="fa fa-arrow-down seta seta-vermelha"
          />
        </small>
      );
    } else {
      return (
        <small>
          <i
            data-for="t-info"
            data-tip="Dentro do valor valor esperado"
            className="fa fa-arrow-up seta seta-verde"
          />
        </small>
      );
    }
  }

  render() {
    let averageDurationDays = this.minutesToDays(
      this.props.time / this.props.count
    );

    return (
      <div className="kt-widget17__item item-low">
        <span className="kt-widget17__desc">
          <span>
            {averageDurationDays} <small className="fs-16">dias</small>
          </span>{" "}
          {this.setArrow(averageDurationDays, this.props.description)}
          <ReactTooltip id="t-info">{}</ReactTooltip>
        </span>
        <span className={this.getClass(this.props.id)}>
          {helpers.splitName(this.props.description)}
          <small className="fs-12">{this.getSLA(this.props.description)}</small>
        </span>
      </div>
    );
  }
}

export default AverageResponseTimeTable;
