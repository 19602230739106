import React from "react";
import Menu from "../components/Menu";
import StickyToolbar from "../components/StickyToolbar";
import TitleComponent from "../components/TitleComponent";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import axios from "axios";
import moment from "moment";
import Select from "../components/Select";
// import Api from "../Api";
import helpers from "../utils/functions";

class Temporais extends React.Component {
  constructor(props) {
    super(props);

    let jwtToken = sessionStorage.getItem("token");
    if (jwtToken && !axios.defaults.headers.common["Authorization"]) {
      axios.defaults.headers.common["Authorization"] = jwtToken;
    }

    let start_date = moment(new Date())
      .subtract(1, "month")
      .format("YYYY-MM-DD");
    let end_date = moment(new Date()).format("YYYY-MM-DD");

    this.state = {
      helpdesks: [],
      products: [],
      years: [],
      startDate: start_date,
      endDate: end_date,
      chartData: [],
      chartDataOptions: [],
      monthlyChartData: [],
      dailyChartData: [],
      selectedYear: "",
      dayOfWeekChartData: [],
      perHourChartData: [],
      baseUrl: "/api/v1/issues",
      selectProduct: "",
      selectHelpdesk: "",
    };
  }

  getConditions = () => {
    let dateStart = moment(this.state.startDate).format("YYYY-MM-DD");
    let dateEnd = moment(this.state.endDate).format("YYYY-MM-DD");

    let helpDeskCondition = "";
    let productCondition = "";
    if (this.state.selectHelpdesk) {
      helpDeskCondition = "&helpdesk=" + this.state.selectHelpdesk;
    }

    if (this.state.selectProduct) {
      productCondition = "&product=" + this.state.selectProduct;
    }

    let queryConditions =
      "start_date=" +
      dateStart +
      " 00:00:00&end_date=" +
      dateEnd +
      " 23:59:59" +
      helpDeskCondition +
      productCondition;
    this.setState({ queryConditions: queryConditions });

    return queryConditions;
  };

  componentDidMount() {
    axios.get(this.state.baseUrl + "/helpdesks").then((res) => {
      this.setState({ helpdesks: res.data });
    });

    axios.get(this.state.baseUrl + "/products").then((res) => {
      this.setState({ products: res.data });
    });

    axios.get(this.state.baseUrl + "/years").then((res) => {
      if (res.data[0]) this.setState({ years: res.data[0].years });
    });

    this.handleUpdateServices();
    this.handleUpdateMonthlyChart(this.state.selectedYear);
  }

  handleUpdateServices = () => {
    let queryConditions = this.getConditions();

    /* Quality vs Time */
    axios
      .get(this.state.baseUrl + "/qualityvstime?" + queryConditions)
      .then((res) => {
        const chartData = {
          datasets: [
            {
              label: "Tempo de Resposta (minutos)",
              type: "line",
              data: [],
              borderColor: "#EC932F",
              backgroundColor: "#EC932F",
              pointBorderColor: "#EC932F",
              pointHoverBorderColor: "#EC932F",
              yAxisID: "y-axis-2",
            },
            {
              type: "bar",
              label: "Avaliação Média",
              data: [],
              backgroundColor: "rgba(35, 181, 181, 0.5019607843137255)",
              borderColor: "rgba(35, 181, 181, 0.8196078431372549)",
              hoverBackgroundColor: "rgba(35, 181, 181, 0.8784313725490196)",
              hoverBorderColor: "rgba(35, 181, 181, 0.9294117647058824)",
              yAxisID: "y-axis-1",
            },
          ],
        };

        const chartDataOptions = {
          maintainAspectRatio: true,
          responsive: true,
          tooltips: { mode: "label" },
          elements: { line: { fill: false } },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                },
                labels: [],
              },
            ],
            yAxes: [
              {
                type: "linear",
                display: true,
                position: "left",
                id: "y-axis-1",
                gridLines: { display: false },
                labels: {
                  show: true,
                },
              },
              {
                type: "linear",
                display: true,
                position: "right",
                id: "y-axis-2",
                gridLines: {
                  display: true,
                },
                labels: {
                  show: true,
                },
              },
            ],
          },
        };

        res.data.forEach(function (item) {
          chartData.datasets[0].data.push(
            (item.average_response / (1000 * 60 * 60)).toFixed(1)
          );
          chartData.datasets[1].data.push(
            item.average_quality == null ? 0 : item.average_quality
          );
          chartDataOptions.scales.xAxes[0].labels.push(item.date);
        });
        this.setState({
          chartData: chartData,
          chartDataOptions: chartDataOptions,
        });
      });

    /* Issues criados por Dia */
    axios.get(this.state.baseUrl + "/daily?" + queryConditions).then((res) => {
      // Chart data
      let dailyChartData = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: "rgba(35, 181, 181, 0.5019607843137255)",
            borderColor: "rgba(35, 181, 181, 0.8196078431372549)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(35, 181, 181, 0.8784313725490196)",
            hoverBorderColor: "rgba(35, 181, 181, 0.9294117647058824)",
          },
        ],
      };
      res.data.forEach(function (item) {
        dailyChartData.labels.push(item.date);
        dailyChartData.datasets[0].data.push(item.total);
      });
      this.setState({ dailyChartData: dailyChartData });
    });

    /* Issues por dia da semana */
    axios
      .get(this.state.baseUrl + "/perdayofweek?=" + queryConditions)
      .then((res) => {
        let dayOfWeekChartData = {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#B6E2C5",
                "#4BA738",
                "#d0c9b0",
                "#ABC645",
                "#AFB5E8",
                "#abb05b",
                "#92afde",
              ],
            },
          ],
        };
        res.data.forEach(function (item) {
          dayOfWeekChartData.labels.push(helpers.getDayName(item._id));
          dayOfWeekChartData.datasets[0].data.push(item.total);
        });

        this.setState({ dayOfWeekChartData: dayOfWeekChartData });
      });

    /* Issues por hora */
    axios
      .get(this.state.baseUrl + "/perhour?=" + queryConditions)
      .then((res) => {
        let perHourChartData = {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: "rgba(35, 181, 181, 0.5019607843137255)",
              borderColor: "rgba(35, 181, 181, 0.8196078431372549)",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(35, 181, 181, 0.8784313725490196)",
              hoverBorderColor: "rgba(35, 181, 181, 0.9294117647058824)",
            },
          ],
        };
        res.data.forEach(function (item) {
          perHourChartData.labels.push(item._id);
          perHourChartData.datasets[0].data.push(item.total);
        });

        this.setState({ perHourChartData: perHourChartData });
      });
  };

  /* Por Mês */
  handleUpdateMonthlyChart = (year) => {
    axios.get(this.state.baseUrl + "/monthly?year=" + year).then((res) => {
      // Chart data
      let monthlyChartData = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: "rgba(35, 181, 181, 0.5019607843137255)",
            borderColor: "rgba(35, 181, 181, 0.8196078431372549)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(35, 181, 181, 0.8784313725490196)",
            hoverBorderColor: "rgba(35, 181, 181, 0.9294117647058824)",
          },
        ],
      };
      res.data.forEach(function (item) {
        monthlyChartData.labels.push(helpers.getMonthName(item._id));
        monthlyChartData.datasets[0].data.push(item.total);
      });
      this.setState({ monthlyChartData: monthlyChartData });
    });
  };

  handleOnClose = (dates) => {
    this.setState(
      { startDate: dates.startDate, endDate: dates.endDate },
      this.handleUpdateServices
    );
  };

  handleChangeProducts = (event) => {
    this.setState(
      { selectProduct: event.target.value },
      this.handleUpdateServices
    );
  };

  handleChangeHelpdesk = (event) => {
    this.setState(
      { selectHelpdesk: event.target.value },
      this.handleUpdateServices
    );
  };

  onChangeMonthly = (e) => {
    this.setState({ selectedYear: e.target.value });
    this.handleUpdateMonthlyChart(e.target.value);
  };

  render() {
    const {
      helpdesks,
      products,
      dailyChartData,
      monthlyChartData,
      selectedYear,
      years,
      dayOfWeekChartData,
      perHourChartData,
      chartData,
      chartDataOptions,
    } = this.state;

    const plugins = [
      {
        afterDraw: (chartInstance, easing) => {
          const ctx = chartInstance.chart.ctx;
          ctx.fillText("This text drawn by a plugin", 100, 100);
        },
      },
    ];

    const doughnut_options = {
      responsive: true,
      legend: {
        position: "right",
      },
      maintainAspectRatio: true,
    };

    const options = {
      responsive: true,
      legend: {
        display: false,
        labels: {
          boxWidth: 20,
          padding: 20,
        },
      },
      maintainAspectRatio: true,
    };

    return (
      <>
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper "
              id="kt_wrapper"
            >
              <Menu />
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-grid--stretch">
                <div
                  className="kt-container kt-body  kt-grid kt-grid--ver"
                  id="kt_body"
                >
                  <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                    <div className="row">
                      <div className="col-md-4">
                        <TitleComponent title={"Indicadores Temporais"} />
                      </div>
                      <div className="col-md-8 align-right">
                        <Select
                          title={"Selecione o Produto"}
                          information={products}
                          value={this.state.selectProduct}
                          onChange={this.handleChangeProducts}
                        />
                        <Select
                          title={"Selecione o Colaborador"}
                          information={helpdesks}
                          value={this.state.selectHelpdesk}
                          onChange={this.handleChangeHelpdesk}
                        />
                        <DateRangePicker
                          isOutsideRange={() => false}
                          displayFormat="YYYY-MM-DD"
                          startDate={
                            this.state.startDate
                              ? moment(this.state.startDate)
                              : null
                          }
                          startDateId="txtStartDate"
                          endDate={
                            this.state.endDate
                              ? moment(this.state.endDate)
                              : null
                          }
                          endDateId="txtEndDate"
                          onDatesChange={({ startDate, endDate }) =>
                            this.setState({ startDate, endDate })
                          }
                          onClose={({ startDate, endDate }) =>
                            this.handleOnClose({ startDate, endDate })
                          }
                          focusedInput={this.state.focusedInput}
                          onFocusChange={(focusedInput) =>
                            this.setState({ focusedInput })
                          }
                        />
                      </div>
                    </div>

                    <div className="kt-content kt-grid__item kt-grid__item--fluid">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="kt-portlet kt-portlet--border-bottom-brand kt-portlet--height-fluid">
                            <div className="kt-portlet__head">
                              <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                  Qualidade do Serviço vs Tempo de Resposta
                                </h3>
                              </div>
                            </div>
                            <div className="kt-portlet__body">
                              <Bar
                                data={chartData}
                                options={chartDataOptions}
                                height={80}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="kt-portlet kt-portlet--border-bottom-brand kt-portlet--height-fluid">
                            <div className="kt-portlet__head">
                              <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                  Tickets por Hora
                                </h3>
                              </div>
                            </div>
                            <div className="kt-portlet__body">
                              <Bar data={perHourChartData} options={options} />
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="kt-portlet kt-portlet--border-bottom-brand kt-portlet--height-fluid">
                            <div className="kt-portlet__head">
                              <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                  Tickets por dia da semana
                                </h3>
                              </div>
                            </div>
                            <div className="kt-portlet__body">
                              <Doughnut
                                data={dayOfWeekChartData}
                                options={doughnut_options}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-12">
                          <div className="kt-portlet kt-portlet--border-bottom-brand kt-portlet--height-fluid">
                            <div className="kt-portlet__head">
                              <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                  Evolução diária
                                </h3>
                              </div>
                            </div>
                            <div className="kt-portlet__body">
                              <Line
                                data={dailyChartData}
                                options={options}
                                height={80}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <hr />

                      <div className="row mt-4">
                        <div className="col-xl-12">
                          <div className="kt-portlet kt-portlet--border-bottom-brand kt-portlet--height-fluid">
                            <div className="kt-portlet__head">
                              <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                  Nº Pedidos Mensais
                                </h3>
                              </div>
                              <div className="kt-portlet__head-toolbar">
                                <select
                                  value={this.state.selectedYear}
                                  onChange={this.onChangeMonthly}
                                  className="form-control kt-selectpicker width-90 select-remast"
                                >
                                  <option value=""></option>
                                  {years.map((y, p) => (
                                    <option key={p} value={y.year}>
                                      {y.year}
                                    </option>
                                  ))}
                                  ;
                                </select>
                              </div>
                            </div>
                            <div className="kt-portlet__body">
                              <Bar
                                data={monthlyChartData}
                                width={100}
                                height={20}
                                options={options}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <StickyToolbar />
      </>
    );
  }
}

export default Temporais;
