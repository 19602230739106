import React from "react";
import logo from "../assets/images/keeps_logo_black_en_vector.svg";
import AdicionarAlerta from "../hooks/AdicionarAlerta";
import Alerta from "../components/Alertas";
import { Redirect } from "react-router-dom";
import "../assets/css/login.css";
import axios from "axios";
// import Api from "../Api";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      showAlert: false,
      redirectToOtherPage: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);

    sessionStorage.removeItem("token");
    sessionStorage.removeItem("name");
  }

  handleSubmit = event => {
    event.preventDefault();

    if (this.state.username === "" || this.state.password === "") {
      this.props.showAlerta({
        text: "Preencha todos os campos",
        classAlert: "alert-warning"
      });
    } else {
      var params = new URLSearchParams();
      params.append("username", this.state.username);
      params.append("password", this.state.password);

      axios
        .post("/api/v1/login", params)
        .then(response => {
          if (response.data.success === false) {
            this.props.showAlerta({
              text: "Credenciais inválidas.",
              classAlert: "alert alert-danger"
            });
          } else {
            this.props.showAlerta({
              text: "Credenciais validadas. A ser redirecionado...",
              classAlert: "alert alert-success"
            });
            sessionStorage.setItem("token", response.data.token);
            sessionStorage.setItem("name", response.data.name);
            axios.defaults.headers.common["Authorization"] =
              response.data.token;

            setInterval(() => {
              this.setState({ redirectToOtherPage: true });
            }, 1000);
          }
        })
        .catch(error => {
          this.props.showAlerta({
            text: "Ocorreu um erro. Tente novamente.",
            classAlert: "alert-warning"
          });
        });
    }
  };

  handleClick = () => {
    this.setState({ showAlert: true });
  };

  render() {
    if (this.state.redirectToOtherPage === true) {
      return <Redirect to="/globais" />;
    }

    return (
      <div className="login">
        <div className="container">
          <div className="col-12 col-md-12 col-lg-12 col-sm-12 contentHight">
            <div className="row justify-content-center contentHight">
              <div className="col-12 col-md-6 col-lg-5 col-sm-12 align-self-center contentLogin">
                <div className="logoImage">
                  <img src={logo} alt="logotipo" />
                </div>
                <div className="form">
                  <form className="formLogin" onSubmit={this.handleSubmit}>
                    {this.state.showAlert && <Alerta />}
                    <div className="form-group">
                      <label className="labelLogin">Utilizador</label>
                      <input
                        type="text"
                        name="username"
                        onChange={e =>
                          this.setState({ username: e.target.value })
                        }
                        className="form-control"
                        id="exampleUsername"
                        placeholder="Introduza o nome do utilizador"
                      />
                    </div>
                    <div className="form-group">
                      <label className="labelLogin">Palavra-Passe</label>
                      <input
                        type="password"
                        name="password"
                        onChange={e =>
                          this.setState({ password: e.target.value })
                        }
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Introduza a palavra-passe"
                      />
                    </div>
                    <button
                      type="submit"
                      id="btnSubmit"
                      onClick={this.handleClick}
                      className="btn buttonLogin"
                    >
                      Entrar
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdicionarAlerta(Login);
