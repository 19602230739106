import React from 'react';
import logo from '../assets/images/keeps_logo_black_en_vector.svg';
import { NavLink } from "react-router-dom";

class Menu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: sessionStorage.getItem("name")
    }
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split('/');
    this.setState({
      activeMenu: pathArray[1],
    });
  }

  render() {
    return (

      <div id="kt_header" className="kt-header kt-grid__item  kt-header--fixed" data-ktheader-minimize="on">
        <div className="kt-header__top">
          <div className="kt-container">
            <div className="kt-header__brand   kt-grid__item">
              <div className="kt-header__brand-logo">
                <img className="logo" alt="Logo" src={logo} />
              </div>
            </div>
            <div className="kt-header__topbar">

              <div className="kt-header__topbar-item kt-header__topbar-item--user">
                <div className="kt-header__topbar-wrapper">
                  <span className="kt-header__topbar-welcome">Olá,</span>
                  <span className="kt-header__topbar-username">{this.state.name}</span>
                  <NavLink to="/" className="kt-menu__link">
                    <span className="kt-header__topbar-icon kt-header__topbar-icon--success">
                      <i className="fa fa-sign-out-alt"></i>
                    </span>
                  </NavLink>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="kt-header__bottom">
          <div className="kt-container">
            <button className="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i className="la la-close"></i></button>
            <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
              <div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile ">
                <ul className="kt-menu__nav ">
                  <li prop={this.state.activeMenu} className={`kt-menu__item  kt-menu__item--submenu kt-menu__item--rel ${this.state.activeMenu == 'globais' ? 'kt-menu__item--here' : 'dddddddd'} `} aria-haspopup="true">
                    <NavLink to="/globais" className="kt-menu__link kt-menu__toggle">
                      <span className="kt-menu__link-text">Globais</span>
                      <i className="kt-menu__ver-arrow la la-angle-right"></i>
                    </NavLink>
                  </li>
                  <li className={`kt-menu__item  kt-menu__item--submenu kt-menu__item--rel ${this.state.activeMenu == 'temporais' ? 'kt-menu__item--here' : ''} `} aria-haspopup="true">
                    <NavLink to="/temporais" className="kt-menu__link kt-menu__toggle">
                      <span className="kt-menu__link-text">Temporais</span>
                      <i className="kt-menu__ver-arrow la la-angle-right"></i>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default Menu;