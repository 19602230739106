import React from "react";
import helpers from "../utils/functions";
import TicketModal from "./TicketModal";
import axios from "axios";

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      baseUrl: "/api/v1/issues",
      show: false,
    };
  }

  render() {
    return (
      <div className="kt-timeline-v2">
        {this.props.information.length == 0 && (
          <p class="center">
            <p className="tbl-p">Sem dados</p>
          </p>
        )}

        <TicketModal
          ticketInformation={this.state.ticketInformation}
          show={this.state.show}
          onHide={this.handleClose}
        />

        {this.props.information.length > 0 &&
          this.props.information.map((item, i) => (
            <div
              key={i}
              className="kt-timeline-v2__items  kt-padding-top-25 kt-padding-bottom-30"
            >
              <div className="kt-timeline-v2__item">
                <span className="kt-timeline-v2__item-time">
                  {helpers.getTime(item.feedback.date)}
                </span>
                <div className="kt-timeline-v2__item-cricle">
                  <i
                    className={`fa fa-genderless kt-font-${this.getClass(
                      item.feedback.evaluation
                    )} `}
                  ></i>
                </div>
                <div className="kt-timeline-v2__item-text kt-padding-top-5">
                  <span className="kt-timeline-v2__item-text-first">
                    Avaliação de{" "}
                    <span
                      className={`kt-link kt-font-bolder kt-link--${this.getClass(
                        item.feedback.evaluation
                      )} `}
                    >
                      {item.feedback.evaluation}
                    </span>{" "}
                    no ticket{" "}
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleShow(item._id);
                      }}
                    >
                      #{item.ticket_id}
                    </a>
                  </span>
                  <br />
                  <i className="flaticon-user"></i> &nbsp;
                  {item.assigned_to.name}
                  <br />
                  {item.feedback.additional_comments && (
                    <span>
                      <i className="flaticon-speech-bubble"></i> &nbsp;
                      {item.feedback.additional_comments}
                      <br />
                    </span>
                  )}
                  <i className="flaticon-calendar-1"></i> &nbsp;
                  {helpers.getDate(item.feedback.date)}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }

  handleShow = (_id) => {
    axios.get(this.state.baseUrl + "/" + _id).then((res) => {
      this.setState({
        show: true,
        ticketInformation: {
          ticket_id: res.data.ticket_id,
          project: res.data.project.name,
          client: res.data.project.client,
          helpdesk: res.data.assigned_to.name,
          priority: res.data.priority.name,
          status: res.data.status.name,
          tracker: res.data.tracker.name,
          author: {
            name: res.data.author.name,
            email: res.data.author.email,
          },
          description: res.data.description,
          subject: res.data.subject,
          evaluation: res.data.feedback.evaluation,
          start_date: res.data.start_date,
          closed_on: res.data.closed_on,
          created_on: res.data.created_on,
        },
      });
    });
  };

  // handleShowTicketInformation = (ticketInformation) => {
  //   console.log(ticketInformation._id);
  //   this.setState({
  //     showTicketInformation: true,
  //     ticketInformation: ticketInformation,
  //   });
  // };

  handleClose = (e) => {
    e.preventDefault();
    this.setState({ show: false });
  };

  getClass(evaluation) {
    var className = "";

    if (evaluation >= 1 && evaluation <= 2) {
      className = "danger";
    } else if (evaluation === 3) {
      className = "warning";
    } else {
      className = "success";
    }
    return className;
  }
}

export default Table;
