import React from "react";
import "../src/assets/css/custom.css";
import "../src/assets/css/style.bundle.css";
import "../src/assets/css/vendors.bundle.css";
import "react-dates/lib/css/_datepicker.css";
import { HashRouter, Route, Switch } from "react-router-dom";
import Globais from "../src/containers/Globais";
import Temporais from "../src/containers/Temporais";
import Login from "../src/containers/Login";

class App extends React.Component {
  render() {
    return (
      <HashRouter>
        <>
          <>
            <Switch>
              <Route exact={true} path="/" render={this.loginOption} />
              <Route exact={true} path="/globais" render={this.globaisOption} />
              <Route
                exact={true}
                path="/temporais"
                render={this.temporaisOption}
              />
            </Switch>
          </>
        </>
      </HashRouter>
    );
  }

  loginOption() {
    return <Login />;
  }

  globaisOption() {
    if (!sessionStorage.getItem("token")) {
      return <Login />;
    }
    return <Globais />;
  }

  temporaisOption() {
    if (!sessionStorage.getItem("token")) {
      return <Login />;
    }
    return <Temporais />;
  }
}

export default App;
