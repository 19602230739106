import React from "react";

class TablePortletHead extends React.Component {
  render() {
    return (
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            {this.props.title}

            {this.props.average && <small className="tt-initial">Média</small>}
          </h3>
        </div>
        <div className="kt-portlet__head-toolbar">
          <div
            className="btn-group btn-group-sm mr-3"
            role="group"
            aria-label="..."
          >
            <button
              onClick={this.props.onClickAsc}
              type="button"
              data-for="t-det"
              data-tip="Ascendente (A-Z)"
              className="btn btn-secondary"
              className={`btn btn-secondary ${
                this.props.order == "1" ? "active" : ""
              } `}
            >
              <i className="fa fa-arrow-up"></i>
            </button>
            <button
              onClick={this.props.onClickDesc}
              type="button"
              data-for="t-det"
              data-tip="Descendente (Z-A)"
              className="btn btn-secondary"
              className={`btn btn-secondary ${
                this.props.order == "-1" ? "active" : ""
              } `}
            >
              <i className="fa fa-arrow-down"></i>
            </button>
          </div>
          <select
            value={this.props.value}
            className="form-control kt-selectpicker width-90 select-remast"
            onChange={this.props.onChange}
          >
            <option value="5">Top 5</option>
            <option value="10">Top 10</option>
            <option value="15">Top 15</option>
          </select>
        </div>
      </div>
    );
  }
}

export default TablePortletHead;
