import React from "react";
import TitleComponent from "../components/TitleComponent";
import GlobalInformation from "../components/GlobalInformation";
import Menu from "../components/Menu";
import StickyToolbar from "../components/StickyToolbar";
import AverageResponseTimeTable from "../components/AverageResponseTimeTable";
import Table from "../components/Table";
import TablePortletHead from "../components/TablePortletHead";
import TimeLine from "../components/EvaluationTimeLine";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import axios from "axios";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import LoadingSpinner from "../components/LoadingSpinner";
import { Pie } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import helpers from "../utils/functions";
import Select from "../components/Select";

class Globais extends React.Component {
  constructor(props) {
    super(props);

    let jwtToken = sessionStorage.getItem("token");
    if (jwtToken && !axios.defaults.headers.common["Authorization"]) {
      axios.defaults.headers.common["Authorization"] = jwtToken;
    }

    let start_date = moment(new Date())
      .subtract(1, "month")
      .format("YYYY-MM-DD");
    let end_date = moment(new Date()).format("YYYY-MM-DD");

    this.state = {
      priorityChartData: [],
      totalTickets: null,
      totalExternalTickets: null,
      withoutEvaluation: null,
      average: null,
      standardDeviation: null,
      responseByPriority: [],
      topAnswers: [],
      topAnswers_average: [],
      topEvaluation: [],
      topEvaluation_average: [],
      lastEvaluations: [],
      startDate: start_date,
      endDate: end_date,
      focusedInput: null,
      helpdesks: [],
      products: [],
      selectProduct: "",
      selectHelpdesk: "",
      selectTopAvaliacoesMedia: "5",
      selectTopRespostasMedia: "5",
      selectTopAvaliacoes: "5",
      selectTopRespostas: "5",
      queryConditions: null,
      orderTopRespostasMedia: "1",
      orderTopRespostas: "1",
      orderTopAvaliacoesMedia: "1",
      orderTopAvaliacoes: "1",
      baseUrl: "/api/v1/issues",
      //token: sessionStorage.getItem("token"),
      loadings: [
        {
          loading_total: false,
          loading_withoutavaliation: false,
          loading_average: false,
          loading_priority: false,
          loading_topanswers: false,
          loading_topanswers_average: false,
          loading_topevaluations: false,
          loading_topevaluations_average: false,
          loading_lastevaluations: false,
        },
      ],
    };

    this.handleChangeProducts = this.handleChangeProducts.bind(this);
    this.handleChangeHelpdesk = this.handleChangeHelpdesk.bind(this);
    this.handleChangeTopAvaliacoesMedia = this.handleChangeTopAvaliacoesMedia.bind(
      this
    );
    this.handleChangeTopRespostasMedia = this.handleChangeTopRespostasMedia.bind(
      this
    );
    this.handleChangeTopRespostas = this.handleChangeTopRespostas.bind(this);
    this.handleChangeTopAvaliacoes = this.handleChangeTopAvaliacoes.bind(this);
    this.handleClickRespostasMediaAsc = this.handleClickRespostasMediaAsc.bind(
      this
    );
    this.handleClickRespostasMediaDesc = this.handleClickRespostasMediaDesc.bind(
      this
    );
    this.handleClickRespostasAsc = this.handleClickRespostasAsc.bind(this);
    this.handleClickRespostasDesc = this.handleClickRespostasDesc.bind(this);
    this.handleClickAvaliacoesMediaAsc = this.handleClickAvaliacoesMediaAsc.bind(
      this
    );
    this.handleClickAvaliacoesMediaDesc = this.handleClickAvaliacoesMediaDesc.bind(
      this
    );
    this.handleClickAvaliacoesAsc = this.handleClickAvaliacoesAsc.bind(this);
    this.handleClickAvaliacoesDesc = this.handleClickAvaliacoesDesc.bind(this);
  }

  getDates = () => {
    let dateStart = moment(this.state.startDate).format("YYYY-MM-DD");
    let dateEnd = moment(this.state.endDate).format("YYYY-MM-DD");

    return {
      dateStart: dateStart,
      dateEnd: dateEnd,
    };
  };

  getConditions = () => {
    let dates = this.getDates();

    let helpDeskCondition = "";
    let productCondition = "";

    if (this.state.selectHelpdesk) {
      helpDeskCondition = "&helpdesk=" + this.state.selectHelpdesk;
    }

    if (this.state.selectProduct) {
      productCondition = "&product=" + this.state.selectProduct;
    }

    let queryConditions =
      "start_date=" +
      dates.dateStart +
      " 00:00:00&end_date=" +
      dates.dateEnd +
      " 23:59:59" +
      helpDeskCondition +
      productCondition;
    this.setState({ queryConditions: queryConditions });

    return queryConditions;
  };

  handleUpdateServices = async () => {
    let baseUrl = this.state.baseUrl;
    let queryConditions = this.getConditions();
    this.setState({ loading_total: true });

    try {
      let totalTickets = 0;
      let totalExternalTickets = 0;

      let totalTickets_res = await axios.get(
        baseUrl + "/total?" + queryConditions
      );

      let totalExternalTickets_res = await axios.get(
        baseUrl + "/total?blacklist=true&" + queryConditions
      );

      if (totalTickets_res.data && totalTickets_res.data[0]) {
        totalTickets = totalTickets_res.data[0].total;
      }

      if (totalExternalTickets_res.data && totalExternalTickets_res.data[0]) {
        totalExternalTickets = totalExternalTickets_res.data[0].total;
      }

      this.setState({
        loading_total: false,
        totalTickets,
        totalExternalTickets,
      });
    } catch (err) {
      console.error(err);
    }

    /*Total de Pedidos externos*/
    this.setState({ loading_total: true });
    axios
      .get(baseUrl + "/total?blacklist=true&" + queryConditions)
      .then((res) => {
        if (res.data && res.data[0]) {
          this.setState({
            loading_total: false,
            totalExternalTickets:
              res.data[0].total != null ? res.data[0].total : 0,
          });
        }
      });

    /*Sem Avaliacao*/
    this.setState({ loading_withoutavaliation: true });

    axios.get(baseUrl + "/notevaluated?" + queryConditions).then((res) => {
      const withoutEvaluation =
        res.data[0] && res.data[0].total != null ? res.data[0].total : 0;
      const cal = (100 * withoutEvaluation) / this.state.totalExternalTickets;
      const withoutEvaluation_percentage = parseFloat(cal).toFixed(2);
      console.log(withoutEvaluation, this.state.totalTickets, cal);

      let evaluatedTickets =
        this.state.totalExternalTickets - withoutEvaluation;

      let evaluatedTickets_percentage = (
        (100 * evaluatedTickets) /
        this.state.totalExternalTickets
      ).toFixed(2);

      this.setState({
        loading_withoutavaliation: false,
        evaluatedTickets,
        evaluatedTickets_percentage,
        withoutEvaluation,
        withoutEvaluation_percentage,
      });
    });

    /* Avaliação Média*/
    this.setState({ loading_average: true });
    axios
      .get(
        baseUrl +
          "/average?" +
          queryConditions /*, { headers: {'authorization': this.state.token } }*/
      )
      .then((res) => {
        let average = "-";
        if (res.data.length > 0) {
          average = res.data[0].average;
        }
        this.setState({ loading_average: false, average });
      });

    /* Desvio padrão */
    axios
      .get(
        baseUrl +
          "/standarddeviation?" +
          queryConditions /*, { headers: {'authorization': this.state.token } }*/
      )
      .then((res) => {
        let standardDeviation = "";
        if (res.data.length > 0) {
          standardDeviation = res.data[0].std;
        }
        this.setState({ standardDeviation });
      });

    /* Tempo de Resposta por prioridade */
    this.setState({ loading_priority: true });
    axios
      .get(baseUrl + "/responsetimebypriority?" + queryConditions)
      .then((res) => {
        console.log("Tempo de Resposta por prioridade", res.data);
        this.setState({
          loading_priority: false,
          responseByPriority: res.data,
        });

        // Chart data
        let priorityChartData = {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [],
            },
          ],
        };
        res.data.forEach(function (item) {
          priorityChartData.labels.push(
            helpers.splitName(item._id.description)
          );
          priorityChartData.datasets[0].data.push(item.count);
          priorityChartData.datasets[0].backgroundColor.push(
            helpers.getPriorityColor(item._id.status)
          );
        });

        this.setState({ priorityChartData: priorityChartData });
      });

    /* Top Respostas */
    this.handleTopRespostas();

    /* Top Respostas - Média */
    this.handleTopRespostasMedia();

    /* Top Avaliações */
    this.handleTopAvaliacoes();

    /* TOP Avaliações - Média */
    this.handleTopAvaliacoesMedia();

    /* Últimas avaliações */
    this.setState({ loading_lastevaluations: true });
    axios
      .get(baseUrl + "/evaluations?" + queryConditions + "&limit=40")
      .then((res) => {
        this.setState({
          loading_lastevaluations: false,
          lastEvaluations: res.data,
        });
      });

    axios.get(baseUrl + "/helpdesks").then((res) => {
      this.setState({ helpdesks: res.data });
    });
    axios.get(baseUrl + "/products").then((res) => {
      this.setState({ products: res.data });
    });
  };

  handleTopRespostas = () => {
    this.setState({ loading_topanswers: true });
    axios
      .get(
        this.state.baseUrl +
          "/topanswers?" +
          this.getConditions() +
          "&order=" +
          this.state.orderTopRespostas +
          "&limit=" +
          this.state.selectTopRespostas
      )
      .then((res) => {
        this.setState({ loading_topanswers: false, topAnswers: res.data });
      });
  };

  handleTopRespostasMedia = () => {
    this.setState({ loading_topanswers_average: true });
    axios
      .get(
        this.state.baseUrl +
          "/topanswers?" +
          this.getConditions() +
          "&order=" +
          this.state.orderTopRespostasMedia +
          "&limit=" +
          this.state.selectTopRespostasMedia +
          "&type=average"
      )
      .then((res) => {
        this.setState({
          loading_topanswers_average: false,
          topAnswers_average: res.data,
        });
      });
  };

  handleTopAvaliacoesMedia = () => {
    this.setState({ loading_topevaluations_average: true });
    axios
      .get(
        this.state.baseUrl +
          "/topevaluations?" +
          this.getConditions() +
          "&order=" +
          this.state.orderTopAvaliacoesMedia +
          "&limit=" +
          this.state.selectTopAvaliacoesMedia +
          "&type=average"
      )
      .then((res) => {
        this.setState({
          loading_topevaluations_average: false,
          topEvaluation_average: res.data,
        });
      });
  };

  handleTopAvaliacoes = () => {
    this.setState({ loading_topevaluations: true });
    axios
      .get(
        this.state.baseUrl +
          "/topevaluations?" +
          this.getConditions() +
          "&order=" +
          this.state.orderTopAvaliacoes +
          "&limit=" +
          this.state.selectTopAvaliacoes
      )
      .then((res) => {
        this.setState({
          loading_topevaluations: false,
          topEvaluation: res.data,
        });
      });
  };

  componentDidMount() {
    this.handleUpdateServices();
  }

  handleOnClose = (dates) => {
    this.setState(
      { startDate: dates.startDate, endDate: dates.endDate },
      this.handleUpdateServices
    );
  };

  handleChangeProducts = (event) => {
    this.setState(
      { selectProduct: event.target.value },
      this.handleUpdateServices
    );
  };

  handleChangeHelpdesk = (event) => {
    this.setState(
      { selectHelpdesk: event.target.value },
      this.handleUpdateServices
    );
  };

  handleChangeTopRespostas = (event) => {
    this.setState(
      { selectTopRespostas: event.target.value },
      this.handleTopRespostas
    );
  };

  handleChangeTopRespostasMedia = (event) => {
    this.setState(
      { selectTopRespostasMedia: event.target.value },
      this.handleTopRespostasMedia
    );
  };

  handleChangeTopAvaliacoes = (event) => {
    this.setState(
      { selectTopAvaliacoes: event.target.value },
      this.handleTopAvaliacoes
    );
  };

  handleChangeTopAvaliacoesMedia = (event) => {
    this.setState(
      { selectTopAvaliacoesMedia: event.target.value },
      this.handleTopAvaliacoesMedia
    );
  };

  handleClickRespostasMediaAsc = () => {
    this.setState(
      { orderTopRespostasMedia: "1" },
      this.handleTopRespostasMedia
    );
  };

  handleClickRespostasMediaDesc = () => {
    this.setState(
      { orderTopRespostasMedia: "-1" },
      this.handleTopRespostasMedia
    );
  };

  handleClickRespostasAsc = () => {
    this.setState({ orderTopRespostas: "1" }, this.handleTopRespostas);
  };

  handleClickRespostasDesc = () => {
    this.setState({ orderTopRespostas: "-1" }, this.handleTopRespostas);
  };

  handleClickAvaliacoesMediaAsc = () => {
    this.setState(
      { orderTopAvaliacoesMedia: "1" },
      this.handleTopAvaliacoesMedia
    );
  };

  handleClickAvaliacoesMediaDesc = () => {
    this.setState(
      { orderTopAvaliacoesMedia: "-1" },
      this.handleTopAvaliacoesMedia
    );
  };

  handleClickAvaliacoesAsc = () => {
    this.setState({ orderTopAvaliacoes: "1" }, this.handleTopAvaliacoes);
  };

  handleClickAvaliacoesDesc = () => {
    this.setState({ orderTopAvaliacoes: "-1" }, this.handleTopAvaliacoes);
  };

  render() {
    const priorityChartOptions = {
      responsive: true,
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 20,
          padding: 20,
        },
      },
    };

    const {
      totalTickets,
      totalExternalTickets,
      withoutEvaluation,
      withoutEvaluation_percentage,
      evaluatedTickets,
      evaluatedTickets_percentage,
      average,
      standardDeviation,
      responseByPriority,
      topAnswers,
      topAnswers_average,
      topEvaluation,
      topEvaluation_average,
      lastEvaluations,
      priorityChartData,
      helpdesks,
      products,
    } = this.state;

    return (
      <>
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper "
              id="kt_wrapper"
            >
              <Menu />
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-grid--stretch">
                <div
                  className="kt-container kt-body  kt-grid kt-grid--ver"
                  id="kt_body"
                >
                  <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                    <div className="row">
                      <div className="col-md-4">
                        <TitleComponent title={"Indicadores Globais"} />
                      </div>
                      <div className="col-md-8 align-right">
                        <Select
                          title={"Selecione o Produto"}
                          information={products}
                          value={this.state.selectProduct}
                          onChange={this.handleChangeProducts}
                        />
                        <Select
                          title={"Selecione o Colaborador"}
                          information={helpdesks}
                          value={this.state.selectHelpdesk}
                          onChange={this.handleChangeHelpdesk}
                        />

                        <DateRangePicker
                          isOutsideRange={() => false}
                          displayFormat="YYYY-MM-DD"
                          startDate={
                            this.state.startDate
                              ? moment(this.state.startDate)
                              : null
                          } // momentPropTypes.momentObj or null,
                          startDateId="txtStartDate" // PropTypes.string.isRequired,
                          endDate={
                            this.state.endDate
                              ? moment(this.state.endDate)
                              : null
                          } // momentPropTypes.momentObj or null,
                          endDateId="txtEndDate" // PropTypes.string.isRequired,
                          onDatesChange={({ startDate, endDate }) =>
                            this.setState({ startDate, endDate })
                          } // PropTypes.func.isRequired,
                          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                          onFocusChange={(focusedInput) =>
                            this.setState({ focusedInput })
                          } // PropTypes.func.isRequired,
                          onClose={({ startDate, endDate }) =>
                            this.handleOnClose({ startDate, endDate })
                          }
                        />
                      </div>
                    </div>

                    <div className="kt-content kt-grid__item kt-grid__item--fluid">
                      <div className="row">
                        <div className="col-xl-8">
                          <div className="row-full-height">
                            <div className="kt-portlet kt-portlet--border-bottom-brand  min-h-252">
                              <div className="kt-portlet__body">
                                <div className="row">
                                  <div className="row col-md-12 min-h-70">
                                    {this.state.loading_total ? (
                                      <LoadingSpinner />
                                    ) : (
                                      <GlobalInformation
                                        title={"Total de Tickets"}
                                        total={totalTickets}
                                      />
                                    )}

                                    {this.state.loading_total ? (
                                      <LoadingSpinner />
                                    ) : (
                                      <GlobalInformation
                                        title={"Tickets por clientes"}
                                        total={totalExternalTickets}
                                      />
                                    )}
                                    {this.state.loading_withoutavaliation ? (
                                      <LoadingSpinner />
                                    ) : (
                                      <GlobalInformation
                                        title={"Tickets avaliados"}
                                        total={`${evaluatedTickets}`}
                                        signal="%"
                                        subtotal={evaluatedTickets_percentage}
                                      />
                                    )}

                                    {this.state.loading_withoutavaliation ? (
                                      <LoadingSpinner />
                                    ) : (
                                      <GlobalInformation
                                        title={"Avaliação Média"}
                                        total={average}
                                        std={standardDeviation}
                                      />
                                    )}
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="kt-widget17">
                                      <p className="lblAverageResponseTimeTable mb-1">
                                        Tempo Médio de Resposta por Prioridade
                                      </p>
                                      <div className="kt-widget17__stats">
                                        <div className="kt-widget17__items">
                                          {this.state.loading_priority ? (
                                            <LoadingSpinner />
                                          ) : (
                                            responseByPriority.map(
                                              (priority, p) => (
                                                <AverageResponseTimeTable
                                                  key={p}
                                                  icon="fa fa-arrow-down kt-font-danger"
                                                  id={priority._id.status}
                                                  description={
                                                    priority._id.description
                                                  }
                                                  count={priority.count}
                                                  time={priority.time}
                                                />
                                              )
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-4">
                          <div className="kt-portlet kt-portlet--border-bottom-brand kt-portlet--height-fluid">
                            <div className="kt-portlet__body pb-0">
                              <p className="lblAverageResponseTimeTable mb-1 center mt-0">
                                Nº Pedidos por prioridade
                              </p>
                              <Pie
                                data={priorityChartData}
                                options={priorityChartOptions}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-8">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="kt-portlet kt-portlet--border-bottom-brand kt-portlet--height-fluid">
                                <TablePortletHead
                                  title={"Tempo de Resposta"}
                                  average={"true"}
                                  value={this.state.selectTopRespostasMedia}
                                  order={this.state.orderTopRespostasMedia}
                                  onChange={this.handleChangeTopRespostasMedia}
                                  onClickAsc={this.handleClickRespostasMediaAsc}
                                  onClickDesc={
                                    this.handleClickRespostasMediaDesc
                                  }
                                />

                                <div className="kt-portlet__body">
                                  <div className="kt-widget11">
                                    {this.state.loading_topanswers_average ? (
                                      <LoadingSpinner
                                        show_text="S"
                                        class={"center"}
                                      />
                                    ) : (
                                      <Table
                                        columnOne={"Colaborador"}
                                        columnTwo={"Tempo Médio"}
                                        average={"yes"}
                                        information={topAnswers_average}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="kt-portlet kt-portlet--border-bottom-brand kt-portlet--height-fluid">
                                <TablePortletHead
                                  title={"Top Avaliações"}
                                  average={"true"}
                                  value={this.state.selectTopAvaliacoesMedia}
                                  order={this.state.orderTopAvaliacoesMedia}
                                  onChange={this.handleChangeTopAvaliacoesMedia}
                                  onClickAsc={
                                    this.handleClickAvaliacoesMediaAsc
                                  }
                                  onClickDesc={
                                    this.handleClickAvaliacoesMediaDesc
                                  }
                                />
                                <div className="kt-portlet__body">
                                  <div className="kt-widget11">
                                    {this.state
                                      .loading_topevaluations_average ? (
                                      <LoadingSpinner
                                        show_text="S"
                                        class={"center"}
                                      />
                                    ) : (
                                      <Table
                                        columnOne={"Colaborador"}
                                        columnTwo={"Avaliação Média"}
                                        top_evaluations={"yes"}
                                        average={"yes"}
                                        information={topEvaluation_average}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="kt-portlet kt-portlet--border-bottom-brand kt-portlet--height-fluid">
                                <TablePortletHead
                                  title={"Tempo de Resposta"}
                                  value={this.state.selectTopRespostas}
                                  order={this.state.orderTopRespostas}
                                  onChange={this.handleChangeTopRespostas}
                                  onClickAsc={this.handleClickRespostasAsc}
                                  onClickDesc={this.handleClickRespostasDesc}
                                />
                                <div className="kt-portlet__body">
                                  <div className="kt-widget11">
                                    {this.state.loading_topanswers ? (
                                      <LoadingSpinner
                                        show_text="S"
                                        class={"center"}
                                      />
                                    ) : (
                                      <Table
                                        columnOne={"Colaborador"}
                                        columnTwo={"Tempo"}
                                        information={topAnswers}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="kt-portlet kt-portlet--border-bottom-brand kt-portlet--height-fluid">
                                <TablePortletHead
                                  title={"Top Avaliações"}
                                  value={this.state.selectTopAvaliacoes}
                                  order={this.state.orderTopAvaliacoes}
                                  onChange={this.handleChangeTopAvaliacoes}
                                  onClickAsc={this.handleClickAvaliacoesAsc}
                                  onClickDesc={this.handleClickAvaliacoesDesc}
                                />
                                <div className="kt-portlet__body">
                                  <div className="kt-widget11">
                                    {this.state.loading_topevaluations ? (
                                      <LoadingSpinner
                                        show_text="S"
                                        class={"center"}
                                      />
                                    ) : (
                                      <Table
                                        columnOne={"Colaborador"}
                                        columnTwo={"Avaliação"}
                                        top_evaluations={"yes"}
                                        information={topEvaluation}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-4">
                          <div className="kt-portlet kt-portlet--border-bottom-brand kt-portlet--height-fluid">
                            <div className="kt-portlet__head">
                              <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                  Últimas avaliações
                                </h3>
                              </div>
                            </div>
                            <div className="kt-portlet__body">
                              <Scrollbars>
                                {this.state.loading_lastevaluations ? (
                                  <LoadingSpinner
                                    class={"center"}
                                    show_text="S"
                                  />
                                ) : (
                                  <TimeLine information={lastEvaluations} />
                                )}
                              </Scrollbars>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <StickyToolbar />
        <ReactTooltip id="t-det">{}</ReactTooltip>
      </>
    );
  }
}

export default Globais;
