import React from "react";
// import Modal from "react-bootstrap/Modal";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import helpers from "../utils/functions";
import TicketModal from "./TicketModal";

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      baseUrl: "/api/v1/issues",
      value: "5",
      show: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleClose(e) {
    e.preventDefault();
    this.setState({ show: false });
  }
  handleShow = (_id) => {
    axios.get(this.state.baseUrl + "/" + _id).then((res) => {
      this.setState({
        show: true,
        ticketInformation: {
          ticket_id: res.data.ticket_id,
          project: res.data.project.name,
          client: res.data.project.client,
          helpdesk: res.data.assigned_to.name,
          priority: res.data.priority.name,
          status: res.data.status.name,
          tracker: res.data.tracker.name,
          author: {
            name: res.data.author.name,
            email: res.data.author.email,
          },
          description: res.data.description,
          subject: res.data.subject,
          evaluation: res.data.feedback.evaluation,
          start_date: res.data.start_date,
          closed_on: res.data.closed_on,
          created_on: res.data.created_on,
        },
      });
    });
  };

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  render() {
    // console.log(this.props.information)
    return (
      <div className="table-responsive">
        <TicketModal
          ticketInformation={this.state.ticketInformation}
          show={this.state.show}
          onHide={this.handleClose}
        />

        {this.props.information.length == 0 && (
          <p class="center">
            <p className="tbl-p">Sem dados</p>
          </p>
        )}

        {this.props.information.length > 0 && (
          <table className="table tbl-dashboards">
            <thead>
              <tr>
                <td>{this.props.columnOne}</td>
                <td className="align-right">{this.props.columnTwo}</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {this.props.top_evaluations &&
                this.props.information.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {this.props.average
                        ? item._id.helpdesk
                        : item.assigned_to.name}
                    </td>
                    <td className="align-right">
                      {this.props.average
                        ? helpers.formatNumber(item.average)
                        : item.feedback.evaluation}
                    </td>
                    <td className="kt-align-right p-btns">
                      {!this.props.average && (
                        <button
                          data-for="t-det"
                          data-tip="Ver detalhes"
                          className="btn btn-hover-brand btn-icon gray btn-sm"
                          onClick={(e) => this.handleShow(item._id)}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}

              {!this.props.top_evaluations &&
                this.props.information.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {this.props.average ? item._id.helpdesk : item.helpdesk}
                    </td>
                    <td className="align-right">
                      {this.props.average
                        ? helpers.formatTime(item.average)
                        : helpers.formatTime(item.duration)}
                    </td>
                    <td className="kt-align-right p-btns">
                      {!this.props.average && (
                        <button
                          data-for="t-det"
                          data-tip="Ver detalhes"
                          className="btn btn-hover-brand btn-icon gray btn-sm"
                          onClick={(e) => this.handleShow(item._id)}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        <ReactTooltip id="t-det">{}</ReactTooltip>
      </div>
    );
  }
}

export default Table;
