import React from "react";

const LoadingSpinner = (props) => (
  <div className={props.class}>
    <i className="fa fa-spinner fa-spin" />
    {props.show_text && <span> A carregar... </span>}
  </div>
);

export default LoadingSpinner;
