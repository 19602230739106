const initialState = {
    text: '',
    classAlert: ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'ALERT_ACTION':
            return {...state, text: action.text, classAlert: action.classAlert}
        default:
        return state
    }
}
